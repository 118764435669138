module.exports = {
  appAddress: "",
  mainPage: "https://automate.video",
  player: "https://automate.video",
  socketIo: "https://api1.automate.video",
  logrocket: "qa6acs/automatevideo",
  mediacacheUrl: "https://mediacache.automate.video/api/",
  downloadServer: "https://download.automate.video",
  apikeys: {
    pixabay: "3350165-a55458b1380d10bb2f850ffa1",
    som_recorderid: "cbloIZjx",
    gdrive: {
      key: "AIzaSyA1kKqteHIgYBvjyIJUS7Q-5mQ5bEo1Amo",
      client_id: "953329306061-t2ss2bfpqige6j20e8tvm01uul5ttkql.apps.googleusercontent.com",
      secret: "rSHgXcvpElHSwVfZAaCpAw3n",
    },
    onedrive: {
      kpass: "dpgpRONM038{+?nhfJWL78$",
    },
    msal: {
      clientID: "6454cf6c-1e44-4197-bee9-eb1c55a05772",
      graphScopes: ["user.read"],
      graphEndpoint: "https://graph.microsoft.com/v1.0/me",
    },
  },
  googleAnalyticsId: "UA-102949881-4",
  newGoogleAnalyticsId: "G-X7MGNFT1QQ",
};
