import store from "../../store";

import axios from "axios";
// import * as ML from './medialibrary';

export const loginActual = (data) => {
  return {
    type: "LOGIN",
    data,
    meta: {
      remote: true,
      client: "webapp",
      version: "0.7.0",
      theme: process.env.REACT_APP_THEME ? process.env.REACT_APP_THEME : "default",
    },
  };
};

export const login = (data) => {
  return (dispatch) => {
    dispatch(loginActual(data));
    //dispatch(generateLoginToken(data));
  };
};

export const setTheme = (theme) => {
  return {
    type: "APP_THEME",
    theme,
  };
};

export const loginWithTokenActual = (token) => {
  // console.log("generateLoginToken: " + JSON.stringify(user));

  let data = {
    loginToken: token,
  };
  return {
    type: "LOGIN_WITH_TOKEN",
    data,
    meta: { remote: true },
  };
};

export const loginWithToken = (token) => {
  // alert("Attempting login with token")
  return (dispatch) => {
    dispatch(loginWithTokenActual(token));
  };
};

export const loginRedirectActual = () => {
  return {
    type: "LOGIN_REDIRECTED",
  };
};
export const loginRedirect = () => {
  return (dispatch) => {
    dispatch(loginRedirectActual());
  };
};

export const newVideo = (data) => {
  console.log("json: " + JSON.stringify(data));
  return {
    type: "CREATE_NEW_VIDEO",
    data,
    meta: { remote: true },
  };
};

export const newVideoTest = (data) => {
  console.log("json: " + JSON.stringify(data));
  return {
    type: "CREATE_VIDEO_TEST",
    data,
    meta: { remote: true },
  };
};

export const renderScenePreview = (data) => {
  console.log("json: " + JSON.stringify(data));
  return {
    type: "RENDER_SCENE_PREVIEW",
    data,
    meta: { remote: true },
  };
};

export const DeleteScene = (data) => {
  console.log("json: " + JSON.stringify(data));
  return {
    type: "DELETE_SCENE",
    data,
    meta: { remote: true },
  };
};

export const DeletePlaceholder = (data) => {
  console.log("json: " + JSON.stringify(data));
  return {
    type: "DELETE_PLACEHOLDER",
    data,
    meta: { remote: true },
  };
};

export const addPlaceholder = (data) => {
  return {
    type: "ADD_PLACEHOLDER",
    data,
    meta: { remote: true },
  };
};

export const addScene = (projectid, modelid, insert_sceneorder) => {
  // { 'projectid':'skhole_a4959f22', modelid: 'skhole_content_scene', insert_sceneorder: 6}
  var data = {
    projectid: projectid,
    modelid: modelid,
    insert_sceneorder: insert_sceneorder,
  };
  return {
    type: "ADD_SCENE",
    data,
    meta: { remote: true },
  };
};
/*
export const importGathercontent = (data) => {
  console.log("json: " + JSON.stringify(data));
  return {
    type: "IMPORT_GATHERCONTENT",
    data,
    meta: { remote: true },
  };
}; */

export const importData = (data) => {
  //console.log("json: " + JSON.stringify(data));
  return {
    type: "IMPORT_DATA",
    data,
    meta: { remote: true },
  };
};

export const AnalyzeVideo = (data) => {
  //console.log("form: " + JSON.stringify(data));
  return {
    type: "ANALYZE_VIDEO",
    data,
    meta: { remote: true },
  };
};

export const editPlaceholder = (data) => {
  //console.log("form: " + JSON.stringify(data));
  return {
    type: "EDIT_PLACEHOLDER",
    data,
    meta: { remote: true },
  };
};

export const editScene = (data) => {
  //console.log("form: " + JSON.stringify(data));
  return {
    type: "EDIT_SCENE",
    data,
    meta: { remote: true },
  };
};

export const modifySceneTiming = (data) => {
  //console.log("form: " + JSON.stringify(data));
  return {
    type: "MODIFY_SCENE_TIMING",
    data,
    meta: { remote: true },
  };
};

export const modifyBulletpointTiming = (data) => {
  //console.log("form: " + JSON.stringify(data));
  return {
    type: "MODIFY_BULLETPOINT_TIMING",
    data,
    meta: { remote: true },
  };
};

export const addMediaToPlaceholder = (data) => {
  console.log("form: " + JSON.stringify(data));
  return {
    type: "ADD_MEDIA_TO_PLACEHOLDER",
    data,
    meta: { remote: true },
  };
};

export const mediaLibraryOpen = () => {
  var data = { opened: true };
  return {
    type: "MEDIA_LIBRARY_DRAWER",
    data,
  };
};

export const mediaLibraryClose = () => {
  var data = { opened: false };
  return {
    type: "MEDIA_LIBRARY_DRAWER",
    data,
  };
};

export const messageShown = () => {
  console.log("message shown ");
  return {
    type: "MESSAGE_SHOWN",
  };
};

export const MediaLibraryPreSelect = (data) => {
  return { type: "MEDIALIBRARY_PRE_SELECT", data };
};

export const MediaLibrarySelectActual = (data) => {
  return { type: "MEDIALIBRARY_SELECTED", data };
};

export const MediaLibrarySelect = (data) => {
  return (dispatch) => {
    dispatch(MediaLibraryPreSelect(true));
    dispatch(MediaLibrarySelectActual(data));
    dispatch(MediaLibraryPreSelect(false));
  };
};

export const uploadFileFailed = (data) => {
  //console.log(JSON.stringify(data));
  return {
    type: "UPLOAD_FILE_FAILED",
    data,
  };
};

export const uploadFileReady = (data) => {
  // console.log("File uploaded: " + data.originalFilename)
  // console.log(JSON.stringify(data))
  //console.log(JSON.stringify(data));
  return {
    type: "UPLOAD_FILE_READY",
    data,
    meta: { remote: true },
  };
};

export const uploadFileItem = (data) => {
  //console.log(JSON.stringify(data));
  return {
    type: "UPLOAD_FILE_ITEM",
    data,
  };
};

export const uploadFileStarting = (data) => {
  //console.log(JSON.stringify(data));
  return {
    type: "UPLOAD_FILE_STARTING",
    data,
    meta: { analytics: true },
  };
};

export const uploadFileUpdateProgress = (data) => {
  //console.log(JSON.stringify(data));
  return {
    type: "UPLOAD_FILE_UPDATE_PROGRESS",
    data,
  };
};

function isMobile() {
  var isMobile = false;
  if ("ontouchstart" in document.documentElement) {
    isMobile = true;
  }
  return isMobile;
}

export function uploadFileGetProgress(data) {
  return (dispatch) => {
    var progress = data.getCompletePercent();
    data.progress = progress;
    if (parseInt(progress) === 100) {
      //dispatch(uploadFileReady(data));
      clearInterval(data.interval);
    }
    dispatch(uploadFileUpdateProgress(data));
  };
}

export function uploadFileSetProgressInterval(data) {
  return (dispatch) => {
    data.interval = setInterval(function () {
      dispatch(uploadFileGetProgress(data));
    }, 1000);
  };
}

export const edit_scene_order = (projectid, sceneid, oldSceneOrder, newSceneOrder) => {
  var data = {
    projectid: projectid,
    sceneid: sceneid,
    old_sceneorder: oldSceneOrder,
    new_sceneorder: newSceneOrder,
  };
  return {
    type: "EDIT_SCENE_ORDER",
    data,
    meta: {
      remote: true,
    },
  };
};

export const slice_video = (data) => {
  return {
    type: "SLICE_VIDEO",
    data,
    meta: {
      remote: true,
    },
  };
};

export const slice_audio = (data) => {
  return {
    type: "SLICE_AUDIO",
    data,
    meta: {
      remote: true,
    },
  };
};

/**
 * Media Library Importers
 */

export const MLPixabayGotImages = (data) => {
  return {
    type: "ML_PIXABAY_IMAGES_READY",
    data,
    // meta: {remote:true}
  };
};

export const MLPixabayLoadingImages = (data) => {
  return {
    type: "ML_PIXABAY_IMAGES_LOADING",
    data,
    // meta: {remote:true}
  };
};

export const MLAddExternalImageToML = (data) => {
  return {
    type: "DOWNLOAD_TO_MEDIALIBRARY",
    data,
    meta: { remote: true },
  };
};

export const MLPixabayGetImages = (search = "", page = 1) => {
  return (dispatch) => {
    dispatch(MLPixabayLoadingImages({ loading: true, data: [], search: search }));
    // ML.PixabayGetImages(search, page, dispatch, MLPixabayGotImages)
  };
};

export const MLPixabayGetVideos = (search = "", page = 1) => {
  return (dispatch) => {
    dispatch(MLPixabayLoadingImages({ loading: true, data: [], search: search }));
    // ML.MLPixabayGetVideos(search, page, dispatch, MLPixabayGotImages)
  };
};

export const H5PCreateSession = (data = null) => {
  return {
    type: "CREATE_H5P_SESSION",
    data,
    meta: { remote: true },
  };
};
